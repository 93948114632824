import { signOut } from 'firebase/auth';
import { LogIn } from './index';
import { useState } from 'react';
import { UserData } from '../components';

function MainPage({ user, setUser, auth }) {
  const [redirect, setRedirect] = useState(false);

  sessionStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('user', JSON.stringify(user));

  function handleRedirect() {
    setRedirect(true);
  }

  function logOut() {
    signOut(auth)
      .then(() => {
        setUser(false);
      })
      .catch((error) => {
        console.error(error);
        alert('We could not log you out!');
      });
  }

  return (
    <div>
      {!redirect && (
        <div className='header'>
          <UserData user={user} logOut={logOut} />
          {!user && (
            <button onClick={handleRedirect} className='button_link'>
              Log In
            </button>
          )}
          {/* Write the rest of the webpage here, below is redirecting thing's */}
        </div>
      )}
      {redirect && <LogIn />}
    </div>
  );
}
export default MainPage;
