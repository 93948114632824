import { doc, updateDoc } from 'firebase/firestore';
import { GetDB, GetAvr } from '.';
async function AddRating(playground, rating) {
  const db = GetDB();

  const docRef = doc(db, 'playgrounds', playground.uid);

  let existingRatings = playground.ratings || [];

  const average = GetAvr(existingRatings, rating);

  await updateDoc(docRef, {
    ratings: [...existingRatings, rating - 1 + 1],
    avrRating: Math.round(average * 10) / 10
  });
}

export default AddRating;
