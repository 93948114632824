import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyAbFwBtIOzFtNBNvzhxi_9Xv-0iYlABMZo',
  authDomain: 'playsavvy-9f672.firebaseapp.com',
  projectId: 'playsavvy-9f672',
  storageBucket: 'playsavvy-9f672.appspot.com',
  messagingSenderId: '783272707849',
  appId: '1:783272707849:web:2ca58f32c45a740aa1f5d8',
  measurementId: 'G-1EC97DEJ6V'
};

// Check if Firebase app has been initialized
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const compressImage = (file, quality) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        // ------------------------------------------------------------------
        // Calculate the new dimensions for the compressed image
        const maxWidth = 640;
        const maxHeight = 640;
        // -------------------------------------------------------------------

        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        // Set the canvas dimensions
        canvas.width = width;
        canvas.height = height;

        // Draw the image on the canvas
        ctx.drawImage(img, 0, 0, width, height);

        // Convert the canvas image to Blob format with the specified quality
        canvas.toBlob(
          (blob) => {
            const compressedFile = new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            });
            resolve(compressedFile);
          },
          'image/jpeg',
          quality
        );
      };

      img.onerror = (error) => {
        reject(error);
      };
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
};

const UploadImage = (file, setProgress) => {
  const uploadOriginal = new Promise((resolve, reject) => {
    const storageRef = firebase
      .storage()
      .ref()
      .child(`images/original/${Date.now()}___${file.name}`);
    const uploadTask = storageRef.put(file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Progress monitoring (optional)
        setProgress(
          Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 50 + 50
          )
        );
      },
      (error) => {
        // Handle upload error
        console.error('Upload error (original):', error);
        reject(error);
      },
      () => {
        // Upload successful
        console.log('Upload successful (original)');

        // Get the download URL of the uploaded file
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then((downloadURL) => {
            // Store the download URL in Firestore or perform further actions
            resolve(downloadURL);
          })
          .catch((error) => {
            console.error('Error getting download URL (original):', error);
            reject(error);
          });
      }
    );
  });

  const uploadCompressed = new Promise((resolve, reject, progress) => {
    compressImage(file, 0.2)
      .then((compressedFile) => {
        const storageRef = firebase
          .storage()
          .ref()
          .child(`images/compressed/${Date.now()}___${file.name}`);
        const uploadTask = storageRef.put(compressedFile);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Progress monitoring (optional)
            setProgress(
              Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 50)
            );
          },
          (error) => {
            // Handle upload error
            console.error('Upload error (compressed):', error);
            reject(error);
          },
          () => {
            // Upload successful
            console.log('Upload successful (compressed)');

            // Get the download URL of the uploaded file
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then((downloadURL) => {
                // Store the download URL in Firestore or perform further actions
                resolve(downloadURL);
              })
              .catch((error) => {
                console.error(
                  'Error getting download URL (compressed):',
                  error
                );
                reject(error);
              });
          }
        );
      })
      .catch((error) => {
        console.error('Error compressing image:', error);
        reject(error);
      });
  });

  return Promise.all([uploadOriginal, uploadCompressed]);
};

export default UploadImage;
