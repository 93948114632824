import { signIn, signInWithGoogle, signUp } from '../scripts';
import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
  onAuthStateChanged
} from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { useState, useEffect } from 'react';
import { Account } from './index';

const firebaseConfig = {
  apiKey: 'AIzaSyAbFwBtIOzFtNBNvzhxi_9Xv-0iYlABMZo',
  authDomain: 'playsavvy-9f672.firebaseapp.com',
  projectId: 'playsavvy-9f672',
  storageBucket: 'playsavvy-9f672.appspot.com',
  messagingSenderId: '783272707849',
  appId: '1:783272707849:web:2ca58f32c45a740aa1f5d8',
  measurementId: 'G-1EC97DEJ6V'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

function LogIn({ userChange }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            setUser(user);
          }
        });
      })
      .catch((error) => {
        console.error('Set persistence error:', error);
      });
  }, []);

  function signInWithGoogleSubmit() {
    signInWithGoogle(auth, (response_user) => {
      setUser(response_user);
    });
  }

  function handleSubmit(e) {
    e.preventDefault();

    signIn(
      auth,
      email,
      password,
      (userCredential) => {
        setUser(userCredential.user);
      },
      (error) => {
        signUp(
          auth,
          email,
          password,
          (userCredential) => {
            setUser(userCredential.user);
          },
          () => {}
        );
        console.warn('Sign in error:', error);
      }
    );
  }

  return (
    <>
      {user !== null ? (
        <Account user={user} setUser={setUser} auth={auth} />
      ) : (
        <div>
          <form onSubmit={handleSubmit}>
            <input
              type='email'
              placeholder='Email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type='password'
              placeholder='Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <input type='submit' />
          </form>
          or <br />
          <button onClick={signInWithGoogleSubmit}>SIGN IN WITH GOOGLE</button>
        </div>
      )}
    </>
  );
}

export { LogIn };
