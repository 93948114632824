import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

function GetDB() {
  const firebaseConfig = {
    apiKey: 'AIzaSyAbFwBtIOzFtNBNvzhxi_9Xv-0iYlABMZo',
    authDomain: 'playsavvy-9f672.firebaseapp.com',
    projectId: 'playsavvy-9f672',
    storageBucket: 'playsavvy-9f672.appspot.com',
    messagingSenderId: '783272707849',
    appId: '1:783272707849:web:2ca58f32c45a740aa1f5d8',
    measurementId: 'G-1EC97DEJ6V'
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  // const analytics = getAnalytics(app);
  const db = getFirestore(app);
  return db;
}
export default GetDB;
