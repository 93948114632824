import { useState, React } from 'react';
import { CardView } from '.';

const Playground = ({ playground }) => {
  // const min_height = 5;
  // const max_height = 10 - min_height;

  // const height = Math.floor((Math.random() + 1) * max_height) + min_height - 1;

  // max_heigth + min_heigth-1  <->  2 * max_heigth + min_heigth - 1

  const [redirect, setRedirect] = useState(false);

  console.log(playground, playground.img_compr)

  const compressed_park_img = playground.img_compr;

  return (
    <>
      {!redirect ? (
        <div
          className='gallery_card'
          /* style={{ height: `${height}rem` }}*/ onClick={() =>
            setRedirect(true)
          }
        >
          <img
            src={playground.img_url}
            className='card-bg'
            alt='Park. Currently loading...'
            style={{ "--background": `url("${compressed_park_img}")` }}
            loading='lazy'
            draggable="false"
          />
          <span className='card_title'>{playground.name}</span>
          <span className='card_rating'>{playground.avrRating}</span>
        </div>
      ) : (
        <CardView playground={playground} setRedirect={setRedirect} />
      )}
    </>
  );
};

export default Playground;
