import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup
} from 'firebase/auth';

const googleProvider = new GoogleAuthProvider();

function signIn(auth, email, password, callback, fail_callback) {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => callback(userCredential))
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.warn(errorCode + errorMessage);
      fail_callback();
    });
}

function signUp(auth, email, password, callback, fail_callback) {
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      callback(userCredential);
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      alert(errorMessage);
      console.error(errorCode + errorMessage);
      fail_callback();
    });
}

function signInWithGoogle(auth, callback_user) {
  signInWithPopup(auth, googleProvider).then((result) => {
    // The signed-in user info.
    callback_user(result.user);
  });
}

export { signIn, signUp, signInWithGoogle };
