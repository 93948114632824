import React, { useState, useEffect } from 'react';
import './css/App.css';
import './css/Normalize.css';
import MainPage from './pages/Main';
import { Application } from './pages';
import { Loader } from './components';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [loadedImageCount, setLoadedImageCount] = useState(0);
  const [totalImageCount, setTotalImageCount] = useState(0);

  useEffect(() => {
    const images = document.querySelectorAll('img');
    setTotalImageCount(images.length);

    const handleImageLoad = () => {
      setLoadedImageCount((prevCount) => prevCount + 1);
    };

    images.forEach((image) => {
      image.addEventListener('load', handleImageLoad);
    });

    return () => {
      images.forEach((image) => {
        image.removeEventListener('load', handleImageLoad);
      });
    };
  }, []);

  useEffect(() => {
    if (loadedImageCount === totalImageCount) {
      setIsLoading(false);
    }
  }, [loadedImageCount, totalImageCount]);

  return (
    <div className='App'>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <MainPage />
          <Application />
        </>
      )}
    </div>
  );
}

export default App;
