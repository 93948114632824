import { useState } from 'react';
import AddPark from '../scripts/AddPark';
import Loader from './Loader';
const ModalAddPark = ({ title, desc, ModalAddParkOpen, setModalAddPark }) => {
  const [parkName, setParkName] = useState('');
  const [parkDesc, setParkDesc] = useState('');
  const [parkAdress, setParkAdress] = useState('');
  const [parkInclude, setParkInclude] = useState('');
  const [parkRating, setParkRating] = useState(4);
  const [parkImage, setParkImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  // const [parkImage, setParkImage] = useState('');

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    await AddPark(
      parkName,
      parkDesc,
      parkAdress,
      parkInclude,
      parkRating,
      parkImage,
      setProgress
    );
    setIsLoading(false);
    setModalAddPark(false);
  }

  return (
    <>
      {isLoading ? (
        <Loader progress={progress} />
      ) : (
        <>
          {ModalAddParkOpen && (
            <div className='modal'>
              <span className='modal_title'> {title} </span>
              <span className='modal_desc'> {desc} </span>

              <form>
                <label>Park Name</label>
                <input
                  type='text'
                  name='ParkName'
                  placeholder='Park Name'
                  value={parkName}
                  onChange={(e) => setParkName(e.target.value)}
                  required
                />

                <label>Quick Description</label>
                <input
                  type='text'
                  name='ParkDesc'
                  placeholder='Park Name'
                  value={parkDesc}
                  onChange={(e) => setParkDesc(e.target.value)}
                  required
                />

                <label>Address</label>
                <input
                  type='text'
                  name='ParkDesc'
                  placeholder='Park Name'
                  value={parkAdress}
                  onChange={(e) => setParkAdress(e.target.value)}
                  required
                />

                <label>What does it include?</label>
                <input
                  type='text'
                  name='ParkDesc'
                  placeholder='Park Name'
                  value={parkInclude}
                  onChange={(e) => setParkInclude(e.target.value)}
                  required
                />

                <label>Rating {parkRating} stars</label>
                <input
                  type='range'
                  min='0'
                  max='5'
                  placeholder='Park Name'
                  value={parkRating}
                  onChange={(e) => setParkRating(e.target.value)}
                  required
                />

                <label>Park Picture</label>
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => setParkImage(e.target.files[0])}
                  required
                />

                <input type='submit' onClick={(e) => handleSubmit(e)} />
              </form>
              <button
                onClick={() => setModalAddPark(false)}
                className='modal_close'
              >
                Close
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ModalAddPark;
