import { useState } from 'react';
import { ModalAddPark } from './index';

const UserData = ({ user, logOut }) => {
  const [ModalAddParkOpen, setModalAddParkOpen] = useState(false);

  return (
    <>
      {user && (
        <>
          <div className='user_info'>
            <h1>
              Hello,{' '}
              <span className='bold'>
                {user.displayName ? user.displayName : user.email}
              </span>
            </h1>

            <button
              className='button_link'
              onClick={() => setModalAddParkOpen(true)}
            >
              Add a Park.
            </button>
            <br />
            <button
              onClick={() => {
                logOut();
              }}
              className='button_link'
            >
              Log Out
            </button>
          </div>

          <ModalAddPark
            title={'Create Park'}
            desc={'Use this to create a park'}
            ModalAddParkOpen={ModalAddParkOpen}
            setModalAddPark={setModalAddParkOpen}
          />
        </>
      )}
    </>
  );
};

export default UserData;
