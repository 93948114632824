import '../css/Loader.scss';

const Loader = ({ progress }) => {
  return (
    <div className='body'>
      <div className='loading-container'>
        <div className='loading-text'>
          <span>L</span>
          <span>O</span>
          <span>A</span>
          <span>D</span>
          <span>I</span>
          <span>N</span>
          <span>G</span>
        </div>
      </div>
      <span className='loading-desc'>
        Do not refresh {progress && <>({progress - 10}%)</>}
      </span>
    </div>
  );
};

export default Loader;
