function GetAvr(existingRatings, rating) {
  const array = existingRatings;
  array.push(rating - 1 + 1);



  if (array.length === 0) {
    return 0; // Handle the case of an empty array
  }

  let sum = 0;
  array.forEach((num) => {
    sum += num;
  });

  const average = sum / array.length;
  return average;
}

export default GetAvr;
