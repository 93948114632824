import React from 'react';
import '../css/App.css';
import '../css/Normalize.css';
import { GetPlaygrounds } from '../scripts';
// import dummy from '../constants/dummyData';
import { Playground } from '../components';

var playgrounds = await GetPlaygrounds();
// playgrounds = [...dummy]; // <- delete for final version

// console.log(playgrounds);

const Application = () => {
  return (
    <div className='app'>
      <input
        type='text'
        placeholder='Search for parks...'
        className='app_search'
      />
      <div className='app_gallery'>
        {playgrounds.map((playground) => (
          <Playground playground={playground} key={playground.id} />
        ))}
      </div>
    </div>
  );
};

export default Application;
