import { useState } from 'react';
import { AddRating } from '../scripts';

const CardView = ({ playground, setRedirect }) => {
  const [rating, setRating] = useState(4);

  async function handleSubmit(e) {
    e.preventDefault();
    await AddRating(playground, rating);
    window.location.reload();
  }

  return (
    <div className='full-screen card-view'>
      <button
        className='card-view_close'
        onClick={() => window.location.reload()}
      >
        Close
      </button>
      <div
        className='gallery_card gallery_card--detail'
        /* style={{ height: `${height}rem` }}*/
      >
        <img src={playground.img_url} className='card-bg' alt='' />
        <span className='card_title'>{playground.name}</span>
        <span className='card_rating'>{playground.avrRating}</span>
      </div>
      <div className='card-view_grid'>
        <p
          dangerouslySetInnerHTML={{ __html: playground.desc }}
          className='card-view_desc'
        ></p>
        <form className='card_grid' onSubmit={(e) => handleSubmit(e)}>
          <label>Rate</label>
          <input
            type='range'
            min={0}
            max={5}
            value={rating}
            onChange={(e) => setRating(e.target.value)}
          />
          <span>{rating} stars</span>
          <input type='submit' />
        </form>
      </div>
    </div>
  );
};

export default CardView;
