import { GetDB } from '.';
import { doc, setDoc, collection } from 'firebase/firestore';
import UploadImage from './UploadImage';

// parkName, parkDesc, parkAdress, parkInclude, parkImage, setProgress
async function AddPark(
  name,
  desc,
  adress,
  include,
  rating,
  file,
  setProgress
) {

  const user = JSON.parse(sessionStorage.getItem('user'));

  const db = GetDB();
  const collectionRef = collection(db, 'playgrounds');
  const newDocRef = doc(collectionRef); // Creates a new document reference

  desc = `${desc} <br />
  <b>This park includes: </b>${include} <br />
  <b>Address: </b>${adress} <br />`;

  const urls = await UploadImage(file, setProgress);

  console.log(urls);

  const downloadURL = urls[0];
  const downloadURL_compressed = urls[1];

  const documentUID = newDocRef.id; // Retrieve the document UID

  await setDoc(newDocRef, {
    avrRating: rating - 1 + 1,
    name: name,
    desc: desc,
    numRating: 0,
    createdBy: user.uid,
    img_url: downloadURL,
    img_compr: downloadURL_compressed,
    adress: adress,
    uid: documentUID,
    ratings: [rating - 1 + 1]
  });

  alert('The upload has now finished, to see the changes refresh the webpage!');

  console.log(downloadURL);
}

export default AddPark;
